<template>
  <div class="app-container flex v">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-model="query.code" :maxlength="100" placeholder="门店编码搜索" class="filter-item" style="width: 180px;"
        clearable @keyup.enter.native="toQuery" />
      <el-input v-model="query.shopName" clearable placeholder="门店名称搜索" style="width: 200px;" class="filter-item"
        @keyup.enter.native="toQuery" />
      <el-input v-model="query.entName" :maxlength="100" placeholder="所属企业搜索" class="filter-item" style="width: 180px;"
        clearable @keyup.enter.native="toQuery" />

      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>

      <span v-if="checkPermission(['SHOPMANAGE_ALL', 'SHOPMANAGE_ADD'])">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </span>

      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading"
        @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" />
    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;" height="100px">
      <!-- <el-table-column prop="erpCode" label="ERP编码" width="100" v-if="$erp.enable()" /> -->
      <el-table-column prop="code" label="门店编码" width="115" />
      <el-table-column prop="shopName" label="门店名称" min-width="120" show-overflow-tooltip />
      <el-table-column prop="entName" label="所属企业" width="250" show-overflow-tooltip />
      <el-table-column prop="erpId" label="企业编码" width="150" />
      <el-table-column prop="dmsOldName" label="dms原名称" min-width="200" show-overflow-tooltip />
      <el-table-column prop="status" label="状态" width="100" />
      <el-table-column prop="mallLocationType" label="商场位置等级" width="155" />
      <el-table-column prop="locationType" label="门店位置等级" width="155" />
      <el-table-column prop="expandPerson" label="拓展人员" width="100" />
      <el-table-column prop="managePerson" label="运营人员" width="100" />
      <el-table-column prop="market" label="商场" width="100" show-overflow-tooltip />
      <el-table-column prop="openDate" label="开店日期" width="90"
        :formatter="v => { return v.openDate ? new Date(v.openDate).format('yyyy/MM/dd') : '' }" />
      <el-table-column prop="propertyName" label="所属物业" width="100" show-overflow-tooltip />
      <el-table-column prop="builtArea" label="建筑面积(㎡)" width="100" />
      <el-table-column prop="useArea" label="实用面积(㎡)" width="100" />
      <!-- <el-table-column prop="openDate" label="撤店日期" width="90" :formatter="v=>{return v.closeDate?new Date(v.closeDate).format('yyyy/MM/dd'):''}" /> -->
      <!-- <el-table-column prop="statusInfo" label="门店状态" width="90" /> -->
      <!-- <el-table-column prop="areaName" label="所属区域" width="100" :formatter="r=>{return formatArea(r.areaId)}" show-overflow-tooltip /> -->
      <el-table-column prop="address" label="地址" min-width="240" />
      <el-table-column prop="enabled" label="启用" width="70">
        <template slot-scope="scope">
          {{ scope.row.enabled ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column width="60" align="center" fixed="right">

        <div class="row-commands" slot-scope="scope" v-if="checkPermission(['SHOPMANAGE_ALL', 'SHOPMANAGE_ADD'])">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
        </div>
        <div class="row-commands" slot-scope="scope" v-else>
          <el-button size="mini" type="text" @click="edit(scope.row)">查看</el-button>
        </div>

      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import { edit } from "@/api/shopInformation";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import eForm from "./detailForm";
export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      downloadLoading: false,
      loading: false,
      areaAry: [], //所在区域数据
      query: {
        erpCode: null,
        value: null,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop/forGroup";
      this.params = Object.assign(this.query, {
        page: this.page,
        size: this.size,
        sort: "createAt,desc"
      });
      return true;
    },

    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/forGroup/download", this.params)
        .then((result) => {
          downloadFile(result, "门店数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm(null, null, true);
    },
    edit(data) {
      // api/shop/getById/{id}  @Jun  api/shopAuth?shopId=xxx
      const p1 = new Promise((resove, reject) => {
        request({
          url: "/api/shop/getById/" + data.id,
          method: "get",
        })
          .then((res) => {
            resove(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      const p2 = new Promise((resove, reject) => {
        request({
          url: "/api/shopAuth?shopId=" + data.id,
          method: "get",
        })
          .then((res) => {
            resove(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      Promise.all([p1, p2])
        .then((result) => {
          if (result && result.length) {
            this.isAdd = false;
            this.$refs.form.restForm(
              JSON.parse(JSON.stringify(result[0])),
              result[1].content, true
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>